import React from 'react';
import SEO from '../components/SEO';

import ContactForm from '../components/forms/ContactForm';
import AnimatedHeading from '../components/AnimatedHeading';
import FadeIn from '../components/Animations/FadeIn';

const ContactPage = () => {
	return (
		<>
			<SEO
				title="Contact"
				pathname="/contact"
				desc="A collaboration of Architects & Master Craftsman"
			/>
			<AnimatedHeading text="Get in touch" />
			<FadeIn>
				<section className="grid grid-cols-1 md:grid-cols-3 gap-8 p-6 md:p-8">
					<div>
						<p>
							To discuss any current or future development
							opportunities, please get in touch via the contact
							form or email{' '}
							<a href="mailto:info@attiqk.com">info@attiqk.com</a>
						</p>
					</div>
					<div className="md:col-span-2">
						<ContactForm />
					</div>
				</section>
			</FadeIn>
		</>
	);
};

export default ContactPage;
