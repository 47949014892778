import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './style.scss';

const ContactForm = () => {
	// Initiate forms
	const { register, handleSubmit, formState, reset } = useForm();
	const [submitted, setSubmitted] = useState(false);

	// Transforms the form data from the React Hook Form output to a format Netlify can read
	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	// Handles the post process to Netlify so we can access their serverless functions
	const handlePost = (formData, event) => {
		fetch(`/`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact-form', ...formData }),
		})
			.then((response) => {
				//navigate('/success/');
				setSubmitted(true);
				reset();
				console.log(response);
			})
			.catch((error) => {
				console.log(error);
			});
		event.preventDefault();
	};

	const showThankYou = (
		<div class="richtext p-6 rounded border border-white border-opacity-10">
			<p>
				Thank you for reaching out. A member of our team will get back
				to you as soon as possible.
			</p>
			<button onClick={() => setSubmitted(false)} class="link">
				Go back
			</button>
		</div>
	);

	const showForm = (
		<form
			onSubmit={handleSubmit(handlePost)}
			name="contact-form"
			method="POST"
			action="/success/"
			data-netlify="true"
			netlify-honeypot="got-ya"
			className="form"
		>
			<input type="hidden" name="form-name" value="contact-form" />
			<input type="hidden" {...register('formId')} value="contact-form" />
			<div className="input-container">
				<label htmlFor="name">
					<p>Name</p>
					<input
						{...register('name', { required: true })}
						placeholder="P Sherman"
					/>
					{formState.errors.name && (
						<p className="error-text">Please tell us your name</p>
					)}
				</label>
			</div>
			<div className="input-container">
				<label htmlFor="email">
					<p>Email</p>
					<input
						{...register('email', {
							required: true,
							pattern:
								/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
						})}
						placeholder="p.sherman@email.com"
					/>
					{formState.errors.name && (
						<p className="error-text">
							Please enter a valid email address
						</p>
					)}
				</label>
			</div>
			<div className="input-container">
				<label htmlFor="email">
					<p>
						Phone number{' '}
						<span className="normal-case font-normal text-sm tracking-normal">
							- (optional)
						</span>
					</p>
					<input
						{...register('phone', {
							pattern:
								/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
						})}
						placeholder="04XXXXXXXX"
					/>
					{formState.errors.phone && (
						<p className="error-text">
							Please enter a valid phone number
						</p>
					)}
				</label>
			</div>
			<div className="input-container">
				<label htmlFor="message">
					<p>Message</p>
					<textarea
						rows="4"
						{...register('message')}
						placeholder="How can we help?"
					/>
				</label>
			</div>
			<label
				htmlFor="got-ya"
				style={{
					position: 'absolute',
					overflow: 'hidden',
					clip: 'rect(0 0 0 0)',
					height: '1px',
					width: '1px',
					margin: '-1px',
					padding: '0',
					border: '0',
				}}
			>
				Don't fill this out if you're human:
				<input tabIndex="-1" {...register('got-ya')} />
			</label>
			<div className="form__footer">
				<button type="submit">Submit</button>
			</div>
		</form>
	);

	return <>{submitted ? showThankYou : showForm}</>;
};

export default ContactForm;
